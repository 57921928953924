import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faTelegram } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

const Footer = () => {
    return(
        <section className='footer'>
        <div className='header-container'>
            <h2>Contact</h2>
        </div>
        <div className='someIcons'>
            <a href='https://t.me/joinchat/AocXz0n1V285em08XdUToQ'>
            <FontAwesomeIcon className='icon' icon={ faTelegram }/>
            </a>
            <a href='https://www.instagram.com/aaltodj/?hl=fi'>
            <FontAwesomeIcon className='icon' icon={ faInstagram }/>
            </a>
            <a href='mailto:info@aalto.dj'>
            <FontAwesomeIcon className='icon' icon={ faEnvelope }/>
            </a>
        </div>
        </section>
    )
}

export default Footer