import React from 'react'

const Section = (props) => {
    if (!props.link && !props.linkText) {
        return(
            <section className='section' id={ props.id }>
            <div className='background-container'>
                <img className='section-background' src={ props.image } alt=''/>
            </div>
            <div className='section-container'>
                <div className='header-container'>
                <h2>{ props.header }</h2>
                </div>
                <div className='text-container'>
                <p>{ props.text }</p>
                </div>
            </div>
            </section>
        )
    } 
    
    else if (!props.link && props.linkText) {
        return(
            <section className='section' id={ props.id }>
            <div className='background-container'>
                <img className='section-background' src={ props.image } alt=''/>
            </div>
            <div className='section-container'>
                <div className='header-container'>
                <h2>{ props.header }</h2>
                </div>
                <div className='text-container'>
                <p>{ props.text }</p>
                <div className='link-container'>
                    <p>{ props.linkText }</p>
                </div>
                </div>
            </div>
            </section>
        )
    }

    else {
        return(
            <section className='section' id={ props.id }>
                <div className='background-container'>
                    <img className='section-background' src={ props.image } alt=''/>
                </div>
                <div className='section-container'>
                    <div className='header-container'>
                    <h2>{ props.header }</h2>
                    </div>
                    <div className='text-container'>
                    <p>{ props.text }</p>
                    <div className='link-container'>
                        <p><a href={ props.link } target="_blank" rel="noreferrer">{ props.linkText }</a></p>
                    </div>
                    </div>
                </div>
                </section>
        )
    }
}

export default Section