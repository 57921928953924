import './App.css'
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Opening from './components/Opening'
import Section from './components/Section'
import Footer from './components/Footer'
import Registry from './components/Registry';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/rekisterit/jasenrekisteri">
          <Registry />
        </Route>
        <Route path="/">
          <div className='container'>
            <Opening/>
            <Section
              header = 'About'
              text = 'AaltoDJ is a DJ / electronic music association within Aalto University. Our goal is to be an open and fun collective of people who share an interest in electronic music and / or DJing. We also provide DJ’s to parties of all kinds and organize our own frequently.'
              id = 'about'
              image = '/1.jpg'
            />
            <Section
              header = 'Join'
              text = 'We welcome all students to join the association. From beginner to pro, you can join our open deck events and other activities either to dive in the secrets of DJing or to teach others and play gigs under the AaltoDJ flag. You can join via Google Form from the link below. Remember to join our Telegram group aswell from the bottom of the page!'
              id = 'join'
              image = '/2.jpg'
              link = 'https://docs.google.com/forms/d/1lE3wg_LD_hGgPIyxRVlrgditH-2hxZdVv2tMYu4k91o/viewform?edit_requested=true'
              linkText = 'Join here!'
            />
            <Section
              header = 'Book Us'
              text = 'You can book us by providing the place, time and requirements by email. You can contact us from the email below. We will answer you as soon as possible.'
              id = 'book'
              image = '/3.jpg'
              linkText = 'info@aalto.dj'
            />
            <Footer/>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
