import React from 'react'

const Opening = () => {
    return(
        <section className='opening' id='opening'>
        <video className='opening-video' autoPlay='autoplay' loop='loop' muted='muted'>
          <source src='/opening.mp4' type='video/mp4'/>
        </video>
        <div className='introduction'>
          <div className='opening-buttons-container'>
            <a href='#about'>
              <button className='opening-button'>About</button>
            </a>
            <a href='#join'>
              <button className='opening-button' href='#opening'>Join</button>
            </a>
            <a href='#book'>
              <button className='opening-button' href='#opening'>Book</button>
            </a>
          </div>
        </div>
        </section>
    )
}

export default Opening