import React from 'react'
import { Link } from 'react-router-dom'

export default function Registry() {
    return (
        <React.Fragment>
            <div className="container registry-container">
            <div className="registry-nav">
                <Link to="/" className="logo">
                    <img className="logo-image" src="/logo_wide.png" height="70px" width="auto" alt="" />
                </Link>
                <Link to="/" className="link">Home</Link>
            </div>
                <h1>Tietosuojaseloste - Jäsenrekisteri</h1>
                <p>Tämä on EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste.<br/><br/>Laatimispäivämäärä: 1.12.2020</p>
                <h2>1. Rekisterinpitäjä</h2>
                <p>AaltoDJ Ry<br/>PL69 02151 Espoo<br/>Y-tunnus: 3100673-9</p>
                <h2>2. Rekisteristä vastaava yhteyshenkilö</h2>
                <p>Sihteeri Tuomas Hukkanen<br/>hallitus@aalto.dj</p>
                <h2>3. Rekisterin nimi</h2>
                <p>AaltoDJ ry:n jäsenrekisteri</p>
                <h2>4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h2>
                <p>EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on rekisterinpitäjän oikeutettu etu.<br/>Henkilötietojen käsittelyn tarkoitus on yhdistyslain (503/1989) 11§ vaatima jäsenrekisterin ylläpito, sekä ylläpitää kerhon jäsenien yhteystietoja.</p>
                <h2>5. Rekisterin tietosisältö</h2>
                <p>Rekisteriin tallennetaan seuraavat tiedot:<br/>Täydellinen nimi (etu- ja sukunimet)<br/>Kotipaikkakunta<br/>AYY:n jäsenyys<br/>Sähköpostiosoite</p>
                <h2>6. Säännönmukaiset tietolähteet</h2>
                <p>Rekisteriin tallennettavat tiedot saadaan yhdistyksen jäseniksi hakevilta sähköisellä lomakkeella.</p>
                <h2>7. Tietojen säilytys ja käsittely</h2>
                <p>Tiedot säilytetään sähköisessä muodossa pilvipalvelussa. Palvelu toteuttaa asianmukaisen sähköisen suojauksen ja käsittelijät tunnistetaan henkilökohtaisella käyttäjätunnuksella ja salasanalla.<br/>Rekisterin tietoihin on pääsy vain AaltoDJ ry:n hallituksen jäsenillä ja tietohallinnosta vastaavalla henkilöllä.</p>
                <h2>8. Rekisterin tietojen säilytysaika</h2>
                <p>Rekisterissä säilytetään vain AaltoDJ ry:n jäsenten tietoja. Rekisteröidyn tiedot poistetaan 1 kuukauden kuluessa rekisteröidyn jätettyä eroilmoituksensa tai saatuaan erottamispäätöksen tietoonsa.<br/>Poikkeustilanteessa rekisteröidyn tietoja voidaan säilyttää pidempään. Tällöin rekisteröidylle ilmoitetaan pidennetystä säilytysajasta ja sen perusteista.</p>
                <h2>9. Tietojen luovuttaminen ja tietojen siirto EU:n tai ETA:n ulkopuolelle</h2>
                <p>Tietoja ei luovuteta kolmansille osapuolille. Tietoja ei luovuteta tai säilytetä EU:n tai Euroopan talousalueen ulkopuolella.</p>
                <h2>10. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h2>
                <p>Jokaisella rekisteröidyllä on oikeus tarkastaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä.<br/>Tietojen tarkistus- ja oikaisupyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisteröidyllä on velvollisuus osoittaa henkilöllisyytensä rekisterinpitäjän niin pyytäessä. Tarkastus- ja oikaisupyynnöt käsitellään 1 kuukauden kuluessa.</p>
            </div>
        </React.Fragment>
    )
}
